import React, { useState } from 'react';
import axios from 'axios';
import Input from '../Input/Input';
import { scroll, validFields } from '../../assets/functions';
import LoaderMail from '../LoaderMail/LoaderMail';

// const axiosLink = "http://localhost:5010";
const axiosLink =  "https://captaincandy.eu";

const ContactForm = () => {
    const [messageSent, setMessageSent] = useState('');
    const [emailProces, setEmailProces] = useState(false);
    const [required, setRequired] = useState(false);
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        if(required) {
            setRequired()
        }
    }

    const send = (e) => {
        e.preventDefault();
        const valid = validFields(data);
        if (valid) {
            const str = valid;
            const str2 = str.charAt(0).toUpperCase() + str.slice(1);
            setRequired({ [valid]: `${str2} is required!` })
            scroll(valid);
        } else {
            setEmailProces(true);
            axios.post(
                `${axiosLink}/webmail/send`,
                data,
            )
            .then(res => {
                setEmailProces(false)
                if (res.data?.done) {
                    setMessageSent("Thank you for contacting us! We appreciate your message and will respond soon.")
                    setTimeout(() => {
                        setMessageSent("");
                        scroll("top");
                    }, 5000);
                    setData({
                        name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        message: "",
                    });
                }
            }).catch(err => {
                setEmailProces(false);
                console.log(err)
            })
        }
    }
    return (
        <form onSubmit={(e) => send(e)} className="ctf mb-5 pl-3">
            <div className="row mb-3 mb-md-5">
                <div className="col-md-4 form-group mb-3 mb-md-0">
                    <Input
                        change={handleChange}
                        defaultValue={data?.name}
                        type="text"
                        name="name"
                        placeholder="Name"
                        validationError={required?.name}
                    />
                </div>
                <div className="col-md-4 form-group mb-3 mb-md-0">
                    <Input
                        change={handleChange}
                        defaultValue={data?.email}
                        type="email"
                        name="email"
                        placeholder="Email"
                        validationError={required?.email}
                    />
                </div>
                <div className="col-md-4 form-group mb-md-0">
                    <Input
                        change={handleChange}
                        defaultValue={data?.phone}
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        validationError={required?.phone}
                    />
                </div>
            </div>

            <div className="row mb-md-5">
                <div className="col-md-12 form-group mb-3 mb-md-0">
                    <Input
                        change={handleChange}
                        defaultValue={data?.subject}
                        type="text" name="subject"
                        placeholder="Subject"
                        validationError={required?.subject}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12 form-group">
                    <Input
                        change={handleChange}
                        defaultValue={data?.message}
                        type="text" name="message"
                        placeholder="Message"
                        validationError={required?.message}
                    />
                </div>
            </div>
            <div className="row">
                {messageSent && <div className='col-12 mb-3'><b style={{ color: "green" }}>{messageSent}</b></div>}
                <div className="col-md-12 form-group d-flex justify-content-end btn-parent">
                    {emailProces ? (
                         <button className="btn-menu-selce" disabled><LoaderMail /></button>
                    ): (
                        <input type="submit" value="SEND" className="btn-menu-selce" disabled={emailProces || required} />
                    )}
                </div>
            </div>
        </form>
)
}

export default ContactForm