import React from 'react';
import Slider from "react-slick";
import "./slider.css";
import AnimationEffects from '../../assets/AnimationContext/AnimationEffects';

const SliderPhotos = ({ data }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const { singleRefs } = AnimationEffects (["title", "slider"], data, 'fadeInY', 0.02, 0.04);

    return (
        <section className="slider-section py-5 bg-white" id="gallery">
            <div className="container">
                <div className="row justify-content-center text-center mb-5">
                    <div className="col-12 mt-4">
                        <h2 className="heading o-0" ref={singleRefs.elements.title.ref}>Gallery</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 overflow-hidden position-relative o-0" id="custom-style" ref={singleRefs.elements.slider.ref}>
                        <Slider {...settings}>
                            {data?.map((el) => (
                                <div key={el.id} className="post-slider">
                                    <div className="media media-custom d-block h-100 rounded-5 overflow-hidden">
                                        <img src={el.img} alt={el.title} className="slider-img" />
                                    </div>
                                    <div className="media-body p-3">
                                        <h2 className="mb-3">{el.title}</h2>
                                        <p>{el?.subTitle}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SliderPhotos;
