import React from 'react';
import { Link } from 'react-router-dom';
import { scroll } from '../../assets/functions';
import AnimationEffects from '../../assets/AnimationContext/AnimationEffects';


const List = ({ list }) => {

    const { singleRefs } = AnimationEffects([], list, 'fadeInY', 0.02, 0.04);
    return (
        <ul className="list-unstyled link">
            {list?.map((el, i) => (
                <li key={el.id}  ref={singleRefs.items[i].ref}>
                    <Link className="text-decoration-none" to={el.id} onClick={() => scroll(el.scrollTo)}>{el.name}</Link>
                </li>
            ))}
        </ul>
    )
}

export default List;
