import React from 'react';
import img1 from "../../assets/images/img_1.jpg";
import img2 from "../../assets/images/img_2.jpg";
import img3 from "../../assets/images/img_3.jpg";
import AnimationEffects from '../../assets/AnimationContext/AnimationEffects';

const data = [
    {
        id: "01",
        img: img1,
        price: "48.30",
        title: "Princess Suite (1 bedroom)",
        desc: "is included one bedroom for two person…",
    },
    {
        id: "02",
        img: img2,
        price: "68.34",
        title: "Family Suite (2 bedroom)",
        desc: "is included two bedroom for four persons…",
    },
    {
        id: "03",
        img: img3,
        price: "80.32",
        title: "Honeymoon Deluxe (Sunset View)",
        desc: "",
    },
]

const Events = () => {
    const { singleRefs } = AnimationEffects([], data, 'fadeInY', 0.02, 0.04);
    const fadeInLeft = AnimationEffects(["leftSide"], [], 'fadeInLeft', 0.02, 0.04);
    const fadeInRight = AnimationEffects(["rightSide"], [], 'fadeInRight', 0.02, 0.04);

    return (
        <section className="section blog-post-entry hero-bg-light" id="rooms">
            <div className="container animate__animated animate__fadeInLeft">
                <div className="row justify-content-center text-center mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h3 className="o-0" ref={fadeInLeft.singleRefs.elements.leftSide.ref}>Find Your True Space</h3>
                        <button className='text-white btn-menu-selce o-0' ref={fadeInRight.singleRefs.elements.rightSide.ref}>View All</button>
                    </div>
                </div>
                <div className="row">
                    {data?.map((el, index) => (
                        <div
                            key={el.id}
                            className="col-lg-4 col-md-6 col-sm-6 col-12 post o-0"
                            ref={singleRefs.items[index].ref}
                        >
                            <div className="media media-custom d-block mb-4 h-100 rounded-5 overflow-hidden">
                                <img src={el.img} alt={el.title} className="img-fluid" />
                                <div className="media-body">
                                    <br />
                                    {/* <p className="meta-post">&euro;{el.price} /<sup className="">night</sup></p> */}
                                    <h2 className="mt-0 mb-3">{el.title}</h2>
                                    <p>{el?.desc}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Events