import React from 'react';
import AnimationEffects from "../../assets/AnimationContext/AnimationEffects";
import './style.css';
import ContactForm from './ContactForm';

const Contact = () => {
  const elementsToAnimate = ['leftSide'];
  const { singleRefs } = AnimationEffects(elementsToAnimate, [], 'fadeInLeft', 0.02, 0.04);
  const newRef = AnimationEffects(["rightSide", "title"], [], 'fadeInRight', 0.02, 0.04);

  return (
    <section className="section contact-section hero-bg-light2" id="contact">
      <div className="container square">
        <div className="row">
          <div className='col-12'>
            <h2 className="mb-4 text-center o-0" ref={newRef.singleRefs.elements.title.ref}>Contact Us</h2>
          </div>
          <div className="col-md-4 o-0" ref={singleRefs.elements.leftSide.ref}>
              <div className="d-flex align-items-center justify-content-end mb-4">
                <div className="contact-info-text text-end">
                  <p className="mb-0"><b>PHONE</b></p>
                  <p>+385 (0)99 360 0012</p>
                </div>
                <svg id="Layer_1" data-name="Layer 1" height="30" width="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
                  <g>
                    <path d="M85.81,120H34.19a8.39,8.39,0,0,1-8.38-8.39V8.39A8.39,8.39,0,0,1,34.19,0H85.81a8.39,8.39,0,0,1,8.38,8.39V111.61A8.39,8.39,0,0,1,85.81,120ZM34.19,3.87a4.52,4.52,0,0,0-4.51,4.52V111.61a4.52,4.52,0,0,0,4.51,4.52H85.81a4.52,4.52,0,0,0,4.51-4.52V8.39a4.52,4.52,0,0,0-4.51-4.52Z"/>
                    <polygon points="73.7 10.32 46.3 10.32 39.28 3.3 42.01 0.57 47.9 6.45 72.1 6.45 77.99 0.57 80.72 3.3 73.7 10.32"/>
                    <rect x="47.1" y="103.23" width="25.81" height="3.87"/>
                  </g>
                </svg>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div className="contact-info-text text-end">
                  <p className="mb-0"><b>ADDRESS</b></p>
                  <p>Šetalište Ivana Jeličića <br />18, 51266 Selce</p>
                </div>
                <svg height="80" width="50" x="0px" y="0px"
                    viewBox="0 0 200 200" enableBackground="new 0 0 200 200">
                  <path fill="#3d1a0f" d="M100.232,149.198c-2.8,0-5.4-1.8-7.2-5.2c-22.2-41-22.4-41.4-22.4-41.6c-3.2-5.1-4.9-11.3-4.9-17.6
                    c0-19.1,15.5-34.6,34.6-34.6s34.6,15.5,34.6,34.6c0,6.5-1.8,12.8-5.2,18.2c0,0-1.2,2.4-22.2,41
                    C105.632,147.398,103.132,149.198,100.232,149.198z M100.332,54.198c-16.9,0-30.6,13.7-30.6,30.6c0,5.6,1.5,11.1,4.5,15.9
                    c0.6,1.3,16.4,30.4,22.4,41.5c2.1,3.9,5.2,3.9,7.4,0c7.5-13.8,21.7-40.1,22.2-41c3.1-5,4.7-10.6,4.7-16.3
                    C130.832,67.898,117.132,54.198,100.332,54.198z"/>
                  <path fill="#3d1a0f" d="M100.332,105.598c-10.6,0-19.1-8.6-19.1-19.1s8.5-19.2,19.1-19.2c10.6,0,19.1,8.6,19.1,19.1
                    S110.832,105.598,100.332,105.598z M100.332,71.298c-8.3,0-15.1,6.8-15.1,15.1c0,8.3,6.8,15.1,15.1,15.1c8.3,0,15.1-6.8,15.1-15.1
                    C115.432,78.098,108.632,71.298,100.332,71.298z"/>
                </svg>
              </div>
              <div className="d-flex align-items-center justify-content-end mb-4">
                <div className='contact-info-text'>
                  <p className="text-end mb-0"><b>EMAIL</b></p>
                  <p> info@pansion.hr</p>
                </div>
                <svg height="25" width="50" viewBox="0 0 64 64"><g><path d="M55,18H1a1,1,0,0,0-1,1V53a1,1,0,0,0,1,1H55a1,1,0,0,0,1-1V19A1,1,0,0,0,55,18ZM54,52H2V20H54Z" /><path d="M59,14H5a1,1,0,0,0,0,2H58V49a1,1,0,0,0,2,0V15A1,1,0,0,0,59,14Z" /><path d="M63,10H9a1,1,0,0,0,0,2H62V45a1,1,0,0,0,2,0V11A1,1,0,0,0,63,10Z" /><path d="M6.11,49.7422,21.292,36.05l6.0942,4.7394a.9986.9986,0,0,0,1.2276,0L34.708,36.05,49.89,49.7422a1,1,0,1,0,1.34-1.4844L36.3113,34.8029,50.4736,23.7891a1,1,0,1,0-1.2275-1.5782L28,38.7334,6.7539,22.2109a1,1,0,1,0-1.2275,1.5782L19.6887,34.8029,4.77,48.2578a1,1,0,1,0,1.34,1.4844Z" /></g></svg>
              </div>
          </div>
          {/* <div className="col-md-1" data-aos="fade-up" data-aos-delay="100"></div> */}
          <div className="col-md-8 o-0" ref={newRef.singleRefs.elements.rightSide.ref}>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;