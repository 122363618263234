import React from 'react';
import bg from "../../assets/images/hero_4.jpg";
import AnimationEffects from '../../assets/AnimationContext/AnimationEffects';
import { Link } from 'react-router-dom';
import { scroll } from '../../assets/functions';

const ReserveNow = () => {
    const fadeInLeft = AnimationEffects(["fadeInLeft"], [], 'fadeInLeft', 0.02, 0.04);
    const fadeInRight = AnimationEffects(["fadeInRight"], [], 'fadeInRight', 0.02, 0.04);

    return (
        <section className="section bg-image overlay" style={{ backgroundImage: `url(${bg})` }}>
            <div className="container" >
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 text-center mb-4 mb-md-0 text-md-left o-0" ref={fadeInLeft.singleRefs.elements.fadeInLeft.ref}>
                        <h2 className="text-white font-weight-bold">A Best Place To Stay. Reserve Now!</h2>
                    </div>
                    <div className="col-12 col-md-6 text-center text-md-end o-0" ref={fadeInRight.singleRefs.elements.fadeInRight.ref}>
                        <Link to="#contact" onClick={() => scroll("contact")} className="btn btn-outline-white-primary py-3 text-white px-5">Reserve Now</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ReserveNow