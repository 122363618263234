import React from 'react';

import maskBg from "../../assets/images/mask-bg.svg";
import img2 from "../../assets/images/food-1.jpg";
import AnimationEffects from '../../assets/AnimationContext/AnimationEffects';

const Hero = ({ desc, hero, customClass, id }) => {
    const elementsToAnimate = ['leftSide'];
    const { singleRefs } = AnimationEffects(elementsToAnimate, [], 'fadeInRight', 0.02, 0.04);
    const newRef = AnimationEffects(["rightSide", "title"], [], 'fadeInLeft', 0.02, 0.04);
  
    return (
        <section className={`py-5 ${customClass}`} id={id}>
            <div className="container pt-5">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-6 ml-auto order-lg-2 position-relative mb-5 o-0" ref={singleRefs.elements.leftSide.ref}>
                        <figure className="img-absolute">
                            <img src={img2} alt={desc} className="img-fluid" />
                        </figure>
                        <div className='position-relative d-flex justify-content-end z-0'>
                            <img src={maskBg} alt={desc} className="mask-hero w-100" />
                            <img src={hero} alt={desc} className="img-fluid rounded w-100 p-extra z-index-1" />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 order-lg-1 o-0"  ref={newRef.singleRefs.elements.rightSide.ref}>
                        {desc}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero