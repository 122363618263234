import React, { Fragment } from 'react';
import { LogoFooter } from '../Logo';
import List from './List';
import AnimationEffects from '../../assets/AnimationContext/AnimationEffects';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { singleRefs } = AnimationEffects(["logo", "title", "subTitle", "Subscribe", "email" ], [], 'fadeInY', 0.02, 0.04);
    return (
        <footer className="section footer-section pb-3 ">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-md-3">
                        <Link action="#" className="footer-logo o-0 text-decoration-none" ref={singleRefs.elements.logo.ref}>
                            <LogoFooter />
                            <span className="mb-0 o-0 text-decoration-none footer-title" ref={singleRefs.elements.title.ref}>Pansion Selce</span>
                            <span  className="o-0 text-decoration-none footer-sub-title" ref={singleRefs.elements.subTitle.ref}>Hotel & Restaurant</span>
                        </Link>
                    </div>
                    <div className="col-md-2 mb-5">
                        <List
                            list={[
                                {
                                    id: "Products",
                                    scrollTo: "rooms",
                                    name: <Fragment><b>Products</b></Fragment>
                                },
                                {
                                    id: "TheRooms",
                                    scrollTo: "rooms",
                                    name: "Rooms"
                                },
                                {
                                    id: "Venue",
                                    scrollTo: "Venuer",
                                    name: "Venue"
                                },
                                {
                                    id: "MettingRomm",
                                    scrollTo: "MettingRomm",
                                    name: "Metting Romm"
                                },
                                {
                                    id: "CustomerStories",
                                    scrollTo: "CustomerStories",
                                    name: "Customer Stories"
                                },
                                {
                                    id: "Pricing",
                                    scrollTo: "Pricing",
                                    name: "Pricing"
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-2 mb-5">
                        <List
                            list={[
                                {
                                    id: "Information",
                                    scrollTo: "about",
                                    name: <Fragment><b>Information</b></Fragment>
                                },
                                {
                                    id: "Booking",
                                    scrollTo: "Booking",
                                    name: "Booking"
                                },
                                {
                                    id: "Media Center",
                                    scrollTo: "Media Center",
                                    name: "Media Center"
                                },
                                {
                                    id: "About Us",
                                    scrollTo: "about",
                                    name: "About Us"
                                },
                                {
                                    id: "Awards",
                                    scrollTo: "Awards",
                                    name: "Awards"
                                },
                                {
                                    id: "CustomerStories",
                                    scrollTo: "CustomerStories",
                                    name: "Customer Stories"
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-2 mb-5">
                        <List list={[
                            {
                                id: "Company",
                                name: <Fragment><b>Company</b></Fragment>
                            },
                            {
                                id: "about",
                                scrollTo: "about",
                                name: ""
                            },
                            {
                                id: "Leadership",
                                scrollTo: "Leadership",
                                name: "Leadership"
                            },
                            {
                                id: "News",
                                scrollTo: "News",
                                name: "News"
                            },
                            {
                                id: "Media Kit",
                                scrollTo: "Media Kit",
                                name: "Media Kit"
                            },
                            {
                                id: "Career",
                                scrollTo: "Career",
                                name: "Career"
                            },
                        ]}
                        />
                    </div>
                    <div className="col-md-3 mb-5">
                        <p ref={singleRefs.elements.Subscribe.ref}>Subscribe Us</p>
                        <form action="#" className="" ref={singleRefs.elements.email.ref}>
                            <input type="email" className="form-control w-100" placeholder="Your email here..." />
                            <button type="submit" className="w-100 my-4 p-2 btn-menu-selce">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer