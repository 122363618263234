const Logo = () => (
    <svg
		x="0px"
		y="0px"
	 	viewBox="0 0 1615.9 495.3"
		style={{ enableBackground: "new 0 0 1615.9 495.3" }}
	>
		<path className="st0" d="M552.4,227.7v35.2c-0.1,5,0.3,9.9,1.1,14.9c0.7,3,2.2,5.7,4.3,8c1.7,1.8,3.8,3.2,6.1,4.1c3,1,6,1.6,9.1,1.7v2.3
			h-62.7v-2.3c3.1,0,6.2-0.6,9.1-1.7c2.3-0.9,4.5-2.3,6.1-4.1c2.2-2.2,3.7-5,4.3-8c0.8-5,1.2-9.9,1.1-14.9v-72.2
			c0.1-4.4-0.1-8.6-0.6-12.9c-0.3-2.7-1.2-5.2-2.6-7.4c-1.6-2.6-3.9-4.7-6.5-5.9c-3.2-1.5-6.6-2.2-10.2-2.3h-0.6v-2.4h59
			c7.6-0.2,15.4,0.3,22.9,1.5c4.9,1.1,9.4,3.3,13.4,6.3c9,6.5,14.3,17,14,28.1c0.7,10.8-4.8,21-14.2,26.2c-5.4,3.1-11.6,5-17.8,5.2
			c-2.3,0.2-7.6,0.2-16,0.2h-19L552.4,227.7z M552.4,224.5h9.5c5.1,0.2,10.1-0.3,15.1-1.5c3.5-0.9,6.7-2.8,9.3-5.2
			c6.4-5.9,9.9-14.5,9.5-23.3c0.1-5.1-0.9-10.1-2.8-14.9c-1.8-4.3-4.5-8-8-11c-4.1-3.4-8.9-5.4-14.2-5.9c-1.5-0.2-4.9-0.2-10.4-0.2
			h-8.2v62H552.4z"/>
		<path className="st0" d="M708,284l1.7,1.1c-2.3,6.7-8.9,11.1-16,10.4c-4.2,0.2-8.2-1-11.6-3.4c-2.6-2.2-4.8-4.9-6.3-7.8
			c-3.1,3-6.3,5.5-9.9,7.8c-4.6,2.5-9.7,3.7-14.9,3.6c-6.1,0.4-12.3-1.5-17.1-5.2c-4.3-3.4-6.6-8.6-6.3-14c-0.2-5.1,1.7-10,5.2-13.6
			c2-2.1,4.3-3.7,6.7-4.9c3.6-1.6,7.2-2.8,11-3.6c5.7-1.5,9.9-2.8,12.7-3.6c2.5-0.8,4.8-2,6.7-3.6c2-1.2,3.5-3.1,4.3-5.2
			c1-2.7,1.4-5.5,1.3-8.4c0.5-5.9-1-11.8-4.3-16.7c-2.8-3.5-7-5.4-11.4-5.5c-2.6-0.1-5.1,0.7-7.2,2.1c-2.2,1.4-3.8,3.4-4.7,5.7
			l-2.6,5.7c-1,3-3.9,4.9-6.9,4.7c-2.1,0.1-4.1-0.7-5.5-2.1c-1.5-1.5-2.3-3.5-2.3-5.5c0-3.4,2.3-6.1,6.9-8.4c3.1-1.4,6.2-2.4,9.5-3
			c3.7-0.8,7.3-1.1,11-1.1c4.3,0,8.5,0.5,12.7,1.5c3.9,0.8,7.5,2.2,11,4.1c3.9,1.9,6.7,5.2,8,9.3c0.6,3.3,0.9,6.6,0.8,9.9v36.3
			c-0.2,4.2,0.1,8.4,0.8,12.5c0.4,4.1,4,7.1,8,6.9c1.6,0.1,3.2-0.3,4.7-1.1C705.7,287.3,707,285.7,708,284z M675.6,245.5
			c-3.7,3-7.5,5.6-11.6,7.8c-4.7,2.6-8,4.5-9.9,5.7c-1.9,1.4-3.6,3-5.1,4.7c-3,3.8-4.7,8.5-4.7,13.4c-0.1,3.9,1.2,7.6,3.6,10.6
			c2.2,2.8,5.5,4.3,9.1,4.3c3.3-0.1,6.4-1.1,9.1-3c2.9-2,5.1-4.9,6.3-8c2.3-6,3.4-12.6,3-19L675.6,245.5L675.6,245.5L675.6,245.5z"/>
		<path className="st0" d="M737.9,208.8c0.9,3.5,1.4,7,1.5,10.6v1.5c7.6-7.9,18.1-12.4,29-12.3c7.2-0.4,14.3,1.7,20.1,5.9
			c5.2,3.9,8,10.1,7.6,16.5v41.5c-0.2,3.3,0.1,6.5,0.8,9.7c0.5,2,1.5,3.8,3,5.1c3,2.2,6.4,3.6,10.2,4.1v2.1h-43.4v-2.1
			c3-0.2,5.7-0.9,8.4-2.1c1.9-1.1,3.4-2.8,4.1-4.9c0.9-2.2,1.4-4.5,1.5-6.7c0.2-3,0.4-7.4,0.4-13.2v-20.8c0.1-5.2-0.1-10.5-0.6-15.6
			c-0.3-2.9-1.3-5.5-2.8-8c-1.6-2.4-3.8-4.2-6.3-5.2c-3-1.3-6.1-2-9.3-1.9c-3.5-0.1-6.9,0.7-10.2,2.1c-3,1.3-5.5,3.3-7.4,5.9
			c-2,2.7-3.3,5.6-3.8,8.9c-0.7,5.4-1,10.9-0.8,16.3v26.4c-0.2,3.3,0.1,6.5,0.8,9.7c0.4,2,1.4,3.8,3,5.1c3,2.2,6.4,3.6,10.2,4.1v2.1
			h-43.5v-2.1c3-0.2,5.7-0.9,8.4-2.1c1.9-1.1,3.4-2.8,4.1-4.9c0.9-2.2,1.4-4.5,1.5-6.7c0.2-3,0.4-7.4,0.4-13.2v-24.4
			c0-6.7-0.2-11.4-0.4-14.2c-0.3-2.3-1-4.6-2.1-6.5c-1-1.7-2.4-3.1-4.1-4.1c-2.2-1-4.5-1.5-6.7-1.5V212L737.9,208.8z"/>
		<path className="st0" d="M821.3,293.5v-27.7h1.5c1.4,5.7,3.7,11.3,6.7,16.3c3.6,6.4,10.2,10.7,17.5,11c3.9,0.1,7.6-1.3,10.6-3.8
			c3.3-3,5.1-7.2,4.9-11.6c0-4-1.3-7.7-3.6-10.8c-1.4-1.8-3-3.3-4.9-4.5c-3.2-2.1-6.5-4.1-10-5.7c-6-2.9-11.6-6.5-16.5-11
			c-7.9-8.4-7.9-21.5,0-29.8c4.7-5,11.2-7.5,18-7.2c3.2,0,6.3,0.6,9.3,1.7l3.4,1.5c0.9,0.4,1.8,0.6,2.8,0.6c1.3,0,2.3-1.1,3.6-3h1.9
			l1.1,22.2h-2.1c-0.5-2.6-1.3-5.2-2.3-7.6c-0.8-2-2-3.9-3.4-5.5c-2-2.4-4.3-4.4-6.9-5.9c-2.4-1.4-5.1-2.1-7.8-2.1
			c-3.6-0.1-6.9,1.4-9.3,4.1c-2.5,2.6-3.9,6.1-3.8,9.7c0,3.2,1.2,6.3,3.4,8.7c3.2,3.1,6.8,5.4,10.8,7.2l10.4,4.7
			c5.4,2.3,10.3,5.9,14,10.6c3.1,4.4,4.7,9.5,4.7,14.9c0.2,6.3-2.2,12.6-6.5,17.1c-5.2,5.2-12.3,8.1-19.7,7.8c-4.6,0-9-0.9-13.2-2.6
			c-3-1.1-4.7-1.7-5.2-1.9c-0.8-0.2-1.6-0.3-2.3-0.2c-2.2,0-4.2,1.1-5.2,3L821.3,293.5z"/>
		<path className="st0" d="M886.6,212.2l28.1-3.6v64c-0.2,3.3,0.1,6.5,0.8,9.7c0.4,2,1.4,3.8,3,5.1c3,2.2,6.4,3.6,10.2,4.1v2.1h-43.4v-2.1
			c2.9-0.2,5.7-0.9,8.5-2.1c1.9-1.1,3.4-2.8,4.1-4.9c0.9-2.2,1.4-4.5,1.5-6.7c0.2-3,0.4-7.4,0.4-13.2v-24.3c0-6.7-0.2-11.4-0.4-14.2
			c-0.2-2.3-1-4.6-2.1-6.5c-0.9-1.7-2.4-3.1-4.1-4.1c-2.2-1-4.5-1.5-6.7-1.5L886.6,212.2L886.6,212.2z M907.1,173.5
			c-2.4,0.1-4.7-0.9-6.3-2.6c-1.7-1.6-2.6-3.9-2.6-6.1s0.9-4.6,2.6-6.1c3.6-3.4,9.1-3.4,12.7,0c1.7,1.6,2.6,3.9,2.6,6.1
			c0.1,2.3-0.9,4.6-2.6,6.1C911.9,172.7,909.5,173.7,907.1,173.5z"/>
		<path className="st0" d="M937.4,252.2c0.1-10.7,4.3-20.9,11.9-28.4c17.3-18.9,46.8-20.1,65.6-2.8c1,0.9,1.9,1.8,2.8,2.8
			c15.6,15.5,15.8,40.5,0.6,56.4c-8.7,9.9-21.5,15.5-34.8,15.1c-13.2,0.3-26-5.2-34.8-15.1c-3.6-3.9-6.3-8.3-8.2-13.2
			C938.5,262.4,937.4,257.4,937.4,252.2z M955.3,251.2c-0.3,10.1,2.3,20.1,7.6,28.8c4.2,7.9,12.3,13,21.2,13.2c3.7-0.1,7.2-1.1,10.4-3
			c3.5-2.1,6.4-4.9,8.7-8.2c2.9-4.2,4.9-8.8,6.1-13.8c1.5-5.2,2.3-10.7,2.3-16c0-5.5-0.9-11-2.6-16.3c-1.4-5.1-3.7-9.8-6.7-14
			c-4.3-6.8-11.7-11-19.7-11c-7.9,0.3-15.2,4.7-19,11.6C957.8,230.9,954.9,240.9,955.3,251.2L955.3,251.2z"/>
		<path className="st0" d="M1066.1,208.8c0.9,3.5,1.4,7,1.5,10.6v1.5c7.6-7.9,18.1-12.4,29-12.3c7.2-0.4,14.3,1.7,20.1,5.9
			c5.2,3.9,8,10.1,7.6,16.5v41.5c-0.2,3.3,0.1,6.5,0.8,9.7c0.5,2,1.5,3.8,3,5.1c3,2.2,6.4,3.6,10.2,4.1v2.1h-43.4v-2.1
			c2.9-0.2,5.7-0.9,8.5-2.1c1.9-1.1,3.4-2.8,4.1-4.9c0.9-2.2,1.4-4.5,1.5-6.7c0.2-3,0.4-7.4,0.4-13.2v-20.8c0.1-5.2-0.1-10.5-0.6-15.6
			c-0.3-2.9-1.3-5.5-2.8-8c-1.6-2.4-3.8-4.2-6.3-5.2c-2.9-1.3-6.1-2-9.3-1.9c-3.5-0.1-6.9,0.7-10.2,2.1c-3,1.3-5.5,3.3-7.4,5.9
			c-2,2.7-3.3,5.6-3.8,8.9c-0.7,5.4-1,10.9-0.8,16.3v26.4c-0.2,3.3,0.1,6.5,0.8,9.7c0.4,2,1.4,3.8,3,5.1c3,2.2,6.4,3.6,10.2,4.1v2.1
			h-43.5v-2.1c2.9-0.2,5.7-0.9,8.5-2.1c1.9-1.1,3.4-2.8,4.1-4.9c0.9-2.2,1.4-4.5,1.5-6.7c0.2-3,0.4-7.4,0.4-13.2v-24.4
			c0-6.7-0.2-11.4-0.4-14.2c-0.3-2.3-1-4.6-2.1-6.5c-0.9-1.7-2.4-3.1-4.1-4.1c-2.2-1-4.5-1.5-6.7-1.5V212L1066.1,208.8z"/>
		<path className="st0" d="M1199.7,293.1l0.2-40.4h2.3c2,8.6,5.2,16.7,9.7,24.4c5.3,9.9,15.6,16.2,26.9,16.5c7,0.2,13.8-2.6,18.6-7.6
			c5-5.2,7.6-12,7.4-19c-0.1-3.5-0.6-6.9-1.7-10.2c-1-3.2-2.5-6.2-4.5-8.9c-2.3-2.9-5-5.3-8-7.4c-5.1-3.3-10.3-6.2-15.6-8.9
			c-4.9-2.4-9.6-5.1-14.2-8c-2.9-1.9-5.6-4.1-8-6.5c-5.6-6-8.7-14.2-8.5-22.5c0-5.4,1.1-10.8,3.4-15.6c2.1-5,5.2-9.3,9.1-12.9
			c9-8,21.3-11.2,33-8.7c3.6,1,7.1,2.2,10.6,3.6c3.2,1.3,5.5,2.1,6.5,2.6c1,0.4,2.1,0.6,3.2,0.6c2.3,0,3.8-1.7,4.7-5.1h2.3l0.6,38.9
			h-2.1c-1.2-4.9-2.8-9.6-4.7-14.2c-1.2-3.2-2.8-6.2-4.7-9.1c-2.7-4.5-6.2-8.3-10.6-11.2c-4.1-2.5-8.6-3.7-13.4-3.6
			c-6.6-0.3-13,2.5-17.3,7.4c-1.8,2.2-3.2,4.6-4.3,7.2c-1,2.7-1.5,5.4-1.5,8.2c0,11,6.9,19.7,21,26.4l13.8,6.7
			c8.8,3.8,16.7,9.5,23.1,16.7c5.2,6.7,8.1,15.2,8,23.8c0,5.9-1.3,11.7-3.6,17.1c-3.1,7.3-8.4,13.5-15.3,17.5
			c-7.2,4.2-15.6,6.3-24,6.1c-8.4,0-16.6-1.7-24.4-5.1c-4.1-1.7-6.5-2.8-7.2-3c-0.8-0.2-1.7-0.4-2.6-0.4c-3-0.1-5.7,1.8-6.5,4.7h-2.1
			L1199.7,293.1L1199.7,293.1z"/>
		<path className="st0" d="M1317.5,240.2c-0.2,3.2-0.4,5.5-0.4,6.9c0,5.6,0.9,11.3,2.6,16.7c1.4,4.9,3.8,9.5,6.7,13.6
			c3,3.7,6.6,6.5,10.8,8.7c4.2,2.1,8.8,3.2,13.6,3.2c3.4,0,6.7-0.6,10-1.7c3-1,5.6-2.7,7.8-4.9c2.9-3.2,5-6.9,6.1-11l2.1,0.6
			c-1.3,7-5.2,13.2-11,17.3c-6.6,4.2-14.4,6.2-22.2,5.9c-7.1,0.2-14.2-1.1-20.7-3.8c-5.8-2.7-10.8-6.7-14.7-11.9
			c-5.6-7.9-8.6-17.5-8.2-27.3c-0.6-13.4,5.3-26.2,15.8-34.5c7.4-6,16.7-9.2,26.2-9.1c5.1-0.1,10,0.9,14.7,2.8c4.3,1.8,8.1,4.6,11.2,8
			c5,5.6,7.5,13.1,7.2,20.5h-57.6L1317.5,240.2L1317.5,240.2z M1360.3,236.4c-0.1-5.2-1.3-10.3-3.6-15.1c-1.6-3.1-4.1-5.7-6.9-7.6
			c-2.8-1.8-6-2.8-9.3-2.8c-5.7,0-11.1,2.7-14.7,7.2c-4.4,5.2-6.9,11.5-7.4,18.2H1360.3z"/>
		<path className="st0" d="M1388.2,159.3l28.1-3.6v116.9c-0.2,3.3,0.1,6.5,0.8,9.7c0.4,2,1.4,3.8,3,5.1c3,2.2,6.4,3.6,10.2,4.1v2.1h-43.4
			v-2.1c2.9-0.2,5.7-0.9,8.5-2.1c1.9-1.1,3.4-2.8,4.1-4.9c0.9-2.2,1.4-4.5,1.5-6.7c0.2-3,0.4-7.4,0.4-13.2v-76.9
			c0-6.5-0.2-11.4-0.4-14.2c-0.2-2.3-1-4.6-2.1-6.5c-0.9-1.7-2.3-3-4.1-3.8c-2.2-1-4.5-1.5-6.7-1.5v-2.3L1388.2,159.3z"/>
		<path className="st0" d="M1509.3,275.1l1.9,0.6c-1.7,6.1-5.4,11.4-10.6,15.1c-5.8,3.5-12.7,5.1-19.5,4.7c-11.6,0.6-22.9-3.8-31.1-11.9
			c-7.4-7.8-11.5-18.4-11-29.2c0.1-5.8,1.1-11.6,3-17.1c1.9-5.2,4.7-10.1,8.2-14.4c4.1-4.6,9-8.2,14.7-10.6c6-2.5,12.6-3.7,19-3.6
			c7.9-0.5,15.7,1.8,22.2,6.3c2.9,2,4.7,5.2,4.9,8.7c0.1,2-0.7,4-2.1,5.2c-1.3,1.5-3.2,2.2-5.1,2.1c-4.3,0-7.4-3.2-9.3-9.3
			c-0.6-2-1.3-4-2.1-5.9c-0.6-1.1-1.4-2-2.3-2.8c-2.2-1.6-4.8-2.4-7.4-2.3c-3.8,0-7.4,1.1-10.6,3.2c-2.9,1.9-5.3,4.3-7.2,7.2
			c-2.3,3.4-4.1,7-5.2,10.8c-2.3,6.2-3.4,12.9-3.4,19.5c0,5.8,1.1,11.7,3.2,17.1c1.9,5.2,4.8,9.9,8.5,14c5,5.6,12.2,8.9,19.7,8.9
			c5.8,0.2,11.5-1.9,15.6-5.9C1505.9,282.4,1507.8,279,1509.3,275.1z"/>
		<path className="st0" d="M1540.2,240.2c-0.2,3.2-0.4,5.5-0.4,6.9c0,5.6,0.9,11.3,2.6,16.7c1.4,4.9,3.8,9.5,6.7,13.6
			c3,3.7,6.6,6.5,10.8,8.7c4.2,2.1,8.8,3.2,13.6,3.2c3.4,0,6.7-0.6,10-1.7c3-1,5.6-2.7,7.8-4.9c2.9-3.2,5-6.9,6.1-11l2.1,0.6
			c-1.3,7-5.2,13.2-11,17.3c-6.6,4.2-14.4,6.2-22.2,5.9c-7.1,0.2-14.2-1.1-20.7-3.8c-5.8-2.7-10.8-6.7-14.7-11.9
			c-5.6-7.9-8.6-17.5-8.2-27.3c-0.6-13.4,5.3-26.2,15.8-34.5c7.4-6,16.7-9.2,26.2-9.1c5.1-0.1,10,0.9,14.7,2.8c4.3,1.8,8.1,4.6,11.2,8
			c5,5.6,7.5,13.1,7.2,20.5h-57.5L1540.2,240.2z M1583,236.4c-0.1-5.2-1.3-10.3-3.6-15.1c-1.6-3.1-4.1-5.7-6.9-7.6
			c-2.8-1.8-6-2.8-9.3-2.8c-5.7,0-11.1,2.7-14.7,7.2c-4.4,5.2-6.9,11.5-7.4,18.2H1583z"/>
		<path className="st0" d="M771.4,401.8v-1.9h5.9v-40h-5.9V358h17.8v1.9h-5.7v18.2H806v-18.2H800V358h17.8v1.9h-5.7v40h4.5
			c0.9,0.2,1.4,1.1,1.3,1.9H800v-1.9h5.9v-19.7h-22.5v19.7h4.5c0.9,0.2,1.4,1.1,1.3,1.9H771.4z"/>
		<path className="st0" d="M848.3,372.6c4.6,3,7.1,8.1,6.7,13.6c0.1,4.2-1.4,8.1-4.3,11.2c-3,3.3-7.2,5.1-11.6,4.9
			c-9.1-0.4-16.1-8-15.8-17.1c0.2-3.9,1.7-7.5,4.3-10.4c3.1-3.2,7.2-5,11.6-4.9C842.3,369.9,845.5,370.9,848.3,372.6z M830.2,392
			c1.3,5.9,4.5,8.7,9.3,8.7c0.9,0,1.9-0.2,2.8-0.4c4.5-1.5,6.7-6.1,6.7-14.4c0.1-1.3,0-2.6-0.2-3.8c-0.8-6.7-4.1-10.2-9.3-10.2
			c-1.2,0-2.3,0.2-3.4,0.6c-4.3,1.7-6.1,6.3-6.1,13.8C829.6,388.2,829.7,390.1,830.2,392L830.2,392z"/>
		<path className="st0" d="M859.1,370.9h5.1v-6.9l5.5-1.5v8.5h11.2v1.9h-11.2v22c0,3.6,1.5,5.5,4.1,5.7c2.3-0.4,4.1-2.6,5.2-6.5l1.9,0.4
			v0.2c-1.1,5.5-3.8,8.2-8.5,8.2c-5.2-0.2-8-3.4-8.5-9.3v-20.5h-5.2V371L859.1,370.9z"/>
		<path className="st0" d="M910.7,396.9c-2.6,3.9-7,6-11.6,5.7c-3.2,0.1-6.2-0.8-8.9-2.6c-4.4-3.1-6.8-8-6.5-13.4
			c-0.1-3.1,0.6-6.1,2.1-8.9c2.4-5.1,7.6-8,13.2-7.6c2.9-0.1,5.7,0.6,8.2,2.1c3.1,1.4,5.1,4.5,5.1,7.8c0.1,1.6-0.7,3.2-1.9,4.3
			c-3.6,1.6-7.5,2.2-11.4,1.7l-8.7-0.2c0,1.9,0.2,3.9,0.6,5.7c1.3,6.1,4.5,9.1,9.5,9.1h1.5c3.8-0.4,6.9-2.9,8.2-6.3l1.9,0.6
			L910.7,396.9z M895.4,372.8c-3.8,2.3-5.9,6.6-5.5,11l7.8,0.2c1.7,0.1,3.4,0,5.1-0.2c2.2-0.4,3.7-2.3,3.6-4.5c0-1-0.2-2.1-0.6-3
			c-1-2.8-3.8-4.6-6.7-4.3C897.8,371.9,896.5,372.1,895.4,372.8L895.4,372.8z"/>
		<path className="st0" d="M932.3,400.1c0.6,0,1.1,0.6,1.1,1.7h-16.7v-1.9c1.2,0,2.4-0.1,3.6-0.4c1.3-0.4,1.9-1.7,1.9-4.1V356h-5.5v-1.9
			h11v45.6h4.1L932.3,400.1z"/>
		<path className="st0" d="M983.1,394.2c2.3,2.6,4.1,4.5,5.1,5.7h3.5c0.8,0,1.3,0.6,1.3,1.9h-10.4c-1.2-1.1-2.2-2.3-3.2-3.6
			c-3.8,2.9-8.4,4.4-13.2,4.3c-3.5,0.1-6.8-1-9.7-3c-3.4-2.5-5.2-6.4-5.1-10.6c0-1.6,0.2-3.2,0.6-4.7c1.9-4.8,6-8.2,11-9.3
			c-1.4-2.7-2.3-5.6-2.6-8.7c-0.1-1.5,0.2-2.9,0.8-4.3c1.8-3.3,5.3-5.2,9.1-4.7c1.2,0,2.3,0.2,3.4,0.6c2.7,1.1,4.4,3.7,4.3,6.5
			c-0.1,1.2-0.4,2.5-0.8,3.6c-1.8,3.3-4.9,5.5-8.5,6.3c3.8,6.3,8.1,12.4,12.9,18c2.5-4.4,3.7-9.2,3.6-14.2H981v-1.9h10.6l-0.2,1.1
			c-0.2,0.6-0.8,0.8-1.9,0.8h-2.6C987.4,383.9,986,389.4,983.1,394.2z M978.2,396.9c-5.4-6.2-10.2-13-14.2-20.1
			c-0.8,0.3-1.5,0.6-2.1,1.1c-2.9,2.2-4.4,5.7-4.1,9.3c0,1.7,0.2,3.5,0.6,5.1c0.8,5.1,5.3,8.6,10.4,8.2
			C972.3,400.3,975.5,399,978.2,396.9L978.2,396.9z M967.8,373c1.7-0.4,3.3-1.2,4.7-2.3c2.2-1.3,3.5-3.7,3.6-6.1
			c0-0.7-0.2-1.4-0.4-2.1c-0.8-2.1-2.9-3.4-5.1-3.2l-1.9,0.2c-2.4,0.9-3.9,3.2-3.6,5.7C965.5,367.9,966.5,370.6,967.8,373L967.8,373z"
			/>
		<path className="st0" d="M1008.5,401.8v-1.9h5.9v-40h-5.9V358h21.6c3.4-0.1,6.6,0.8,9.5,2.6c3,1.9,4.8,5.2,4.7,8.7
			c-0.1,1.5-0.4,3-0.8,4.5c-1.9,3.8-5.3,6.4-9.5,7.2l11.6,18.8h3.6c0.6,0.2,0.8,0.8,0.8,1.9h-11l-11.6-20.1h-6.9v18.2h5.7v1.9
			L1008.5,401.8L1008.5,401.8z M1020.6,360v19.7h7.4c2.1,0,4.1-0.7,5.7-1.9c2.4-2.1,3.8-5.1,3.6-8.2c0-1.5-0.3-3.1-0.8-4.5
			c-1.6-3.4-5.1-5.3-8.7-5.1L1020.6,360L1020.6,360z"/>
		<path className="st0" d="M1079.6,396.9c-2.6,3.9-7,6-11.6,5.7c-3.2,0.1-6.2-0.8-8.9-2.6c-4.4-3.1-6.8-8-6.5-13.4
			c-0.1-3.1,0.6-6.1,2.1-8.9c2.4-5.1,7.6-8,13.2-7.6c2.9-0.1,5.7,0.6,8.2,2.1c3.1,1.4,5.1,4.5,5.1,7.8c0.1,1.6-0.7,3.2-1.9,4.3
			c-3.6,1.6-7.5,2.2-11.4,1.7l-8.7-0.2c0,1.9,0.2,3.9,0.6,5.7c1.3,6.1,4.5,9.1,9.5,9.1h1.5c3.8-0.4,6.9-2.9,8.2-6.3l1.9,0.6
			L1079.6,396.9z M1064.4,372.8c-3.8,2.3-5.9,6.6-5.5,11l7.8,0.2c1.7,0.1,3.4,0,5.1-0.2c2.2-0.4,3.7-2.3,3.6-4.5c0-1-0.2-2.1-0.6-3
			c-1-2.8-3.8-4.6-6.7-4.3C1066.8,371.9,1065.5,372.1,1064.4,372.8L1064.4,372.8z"/>
		<path className="st0" d="M1097.1,400.5c2.7,0.3,5.2-0.8,6.7-3c0.4-0.7,0.7-1.5,0.6-2.3c0-2.1-1.5-3.8-4.5-5.2c-1-0.6-2.1-1.1-3.2-1.5
			c-3-1.1-5.6-2.7-8-4.7c-1.4-1.6-2.2-3.6-2.1-5.7c0-3.3,2.3-6.2,5.5-6.9c1.8-0.6,3.7-0.9,5.5-0.8c2.9-0.1,5.7,0.6,8.2,2.1
			c0-1.5,0.4-2.1,1.1-2.3h0.8v9.1h-1.5c0-4.5-2.6-6.7-7.4-6.7c-3.6,0-5.9,1.1-6.7,3.2l-0.4,1.9c0,2.1,1.5,3.8,4.5,5.2
			c1,0.6,2.1,1.1,3.2,1.5c2.9,1,5.5,2.6,7.8,4.7c1.4,1.5,2.2,3.5,2.1,5.5c0.1,3.9-2.6,7.1-6.3,7.8c-1.7,0.6-3.5,0.9-5.2,0.8
			c-3.2,0.1-6.4-0.8-9.1-2.6c0,1.3-0.2,1.9-0.8,2.1h-1.1v-11.1h2.1C1089,397.3,1091.7,400.5,1097.1,400.5z"/>
		<path className="st0" d="M1113.5,370.9h5.1v-6.9l5.5-1.5v8.5h11.2v1.9h-11.1v22c0,3.6,1.5,5.5,4.1,5.7c2.3-0.4,4.1-2.6,5.2-6.5l1.9,0.4
			v0.2c-1.1,5.5-3.8,8.2-8.5,8.2c-5.2-0.2-8-3.4-8.5-9.3v-20.5h-5.2V371L1113.5,370.9z"/>
		<path className="st0" d="M1158,396c-0.5,1.1-1.1,2.1-1.9,3c-2.4,2.4-5.5,3.7-8.9,3.6c-1.5,0-2.9-0.3-4.3-0.8c-3.2-1.2-5.2-4.3-4.9-7.6
			c0-1,0.2-2,0.4-3c1.7-4.9,8.2-7.2,19.7-7.2v-4.7c0.3-3.8-2.4-7-6.1-7.3h-0.8c-1.7-0.1-3.3,0.6-4.3,1.9c-0.2,0.4-0.6,1.5-1.5,3.2
			c-0.6,1.5-1.3,2.1-2.6,2.1h-0.6c-1.2-0.1-2.2-1.1-2.2-2.3v-0.2c0-0.9,0.3-1.8,0.8-2.6c2.9-3,6.9-4.4,11-3.8h1.3
			c5.3-0.4,10,3.7,10.4,9l-0.1,2v15.5c0,2.3,0.6,3.6,1.9,3.8c1.5,0,2.6-1.9,2.8-5.2h1.9v0.8c-0.4,4.5-2.6,6.7-6.1,6.7
			C1160.7,402.6,1157.9,399.6,1158,396z M1152.7,398.8c2.8-1.3,4.8-4,5.2-6.9v-5.9c-2.9-0.1-5.8,0.1-8.7,0.6c-3.8,0.8-5.7,3-5.7,6.1
			c0,0.8,0,1.6,0.2,2.3c0.4,2.7,2.8,4.7,5.5,4.5C1150.5,399.6,1151.8,399.4,1152.7,398.8L1152.7,398.8z"/>
		<path className="st0" d="M1208.4,400.1c0.6,0,1.1,0.6,1.1,1.7h-10.4l-0.4-7.4c-0.6,1.3-1.3,2.6-2.1,3.8c-2.1,2.9-5.3,4.5-8.9,4.5
			c-6.9,0-10.4-4.3-10.4-12.5v-17.3h-5.7v-1.9h11v20.3c-0.6,3.9,2.1,7.5,5.9,8.1c1.9,0.3,3.9-0.2,5.4-1.4c3.1-2.2,4.9-5.7,4.7-9.5
			v-15.6h-5.9v-1.9h11.4v29.2L1208.4,400.1L1208.4,400.1z"/>
		<path className="st0" d="M1230.4,400.1c0.6,0,1.1,0.6,1.1,1.7h-18v-1.9c1.2,0,2.4-0.1,3.6-0.4c1.3-0.4,1.9-1.7,1.9-4.1v-22.9h-5.5v-1.9
			h10.6l0.2,7.2c0.7-1.5,1.5-2.8,2.6-4.1c1.9-2.4,4.8-3.8,7.8-3.8l1.5,0.2c2.1,0.2,3.6,2,3.4,4.1l-0.2,0.8c-0.2,1.3-1.4,2.3-2.8,2.1
			l-0.8-0.2c-1-0.5-1.7-1.3-2.1-2.3c-0.6-1.3-1.5-1.9-2.3-1.9c-1.1,0.2-2.1,0.8-2.8,1.7c-2.8,2.8-4.3,6.6-4.1,10.6v14.7h5.2
			L1230.4,400.1z"/>
		<path className="st0" d="M1262,396c-0.5,1.1-1.1,2.1-1.9,3c-2.4,2.4-5.5,3.7-8.9,3.6c-1.5,0-2.9-0.3-4.3-0.8c-3.2-1.2-5.2-4.3-4.9-7.6
			c0-1,0.2-2,0.4-3c1.7-4.9,8.2-7.2,19.7-7.2v-4.7c0.3-3.8-2.4-7-6.1-7.3h-0.8c-1.7-0.1-3.3,0.6-4.3,1.9c-0.2,0.4-0.6,1.5-1.5,3.2
			c-0.6,1.5-1.3,2.1-2.6,2.1h-0.6c-1.2-0.1-2.2-1.1-2.2-2.3v-0.2c0-0.9,0.3-1.8,0.8-2.6c2.9-3,6.9-4.4,11-3.8h1.3
			c5.3-0.4,10,3.7,10.4,9l-0.1,2v15.5c0,2.3,0.6,3.6,1.9,3.8c1.5,0,2.6-1.9,2.8-5.2h1.9v0.8c-0.4,4.5-2.6,6.7-6.1,6.7
			C1264.6,402.4,1262.6,400.3,1262,396z M1256.7,398.8c2.8-1.3,4.8-4,5.2-6.9v-5.9c-2.9-0.1-5.8,0.1-8.7,0.6c-3.8,0.8-5.7,3-5.7,6.1
			c0,0.8,0,1.6,0.2,2.3c0.4,2.7,2.8,4.7,5.5,4.5C1254.5,399.6,1255.7,399.4,1256.7,398.8z"/>
		<path className="st0" d="M1291.4,400.1c0.6,0,1.1,0.6,1.1,1.7h-16v-1.9c1.2,0,2.4-0.1,3.6-0.4c1.3-0.4,1.9-1.7,1.9-4.1v-22.9h-5.5v-1.9
			h10.6l0.2,7.2c0.6-1.3,1.3-2.6,2.1-3.8c2.1-2.8,5.4-4.4,8.9-4.3c1.8,0,3.6,0.5,5.1,1.5c3.5,2.3,5.4,6.2,5.1,10.4v18h4.3
			c0.6,0,1.1,0.6,1.1,1.7h-16.5v-1.9c1.2,0,2.4-0.1,3.6-0.4c1.3-0.4,1.9-1.7,1.9-4.1v-14.4c0-1.3-0.2-2.6-0.6-3.8
			c-0.9-2.7-3.4-4.4-6.1-4.3c-1.4,0-2.9,0.5-4.1,1.3c-3.2,2.3-5,5.8-4.9,9.7v15.6h3.2C1291,400.1,1291.2,400.1,1291.4,400.1z"/>
		<path className="st0" d="M1316.9,370.9h5.1v-6.9l5.5-1.5v8.5h11.2v1.9h-11.2v22c0,3.6,1.5,5.5,4.1,5.7c2.3-0.4,4.1-2.6,5.2-6.5l1.9,0.4
			v0.2c-1.1,5.5-3.8,8.2-8.5,8.2c-5.2-0.2-8-3.4-8.5-9.3v-20.5h-5.2V371L1316.9,370.9z"/>
		<path className="st0" d="M381,104.4v325.5c-0.5,18.7,14.3,34.3,32.9,34.8h1.8c19,0.2,34.5-15,34.8-34v-0.8c-0.2-7.1-2.3-14.2-5.9-20.3
			c-9.9-19-5.1-24.6-1.7-28.8c-9.3-1.9-14.4,5.9-17.8,12.7c-2.6,0-5.2,0.3-7.6,0.8c-6.5,1.5-8.4,6.3-0.8,6.7
			c14.3-0.8,26.4,10.1,27.2,24.4c0.1,1.5,0,3-0.1,4.5c0,18.6-9.3,27.9-27.9,27.9c-18.6,0-27.9-9.3-27.9-27.9v-51.4
			c0-13.8,20.3-14.4,26.2-15.3c0-11-11-11-20.3-10.2l-5.9-9.3v-43.6c2.6-15.5,11.2-23.3,23.3-32.2l1.3-0.6l-1.3-0.6
			c-12.1-8.9-21-16.5-23.3-32.2v-43.6l5.9-9.3c9.3,0.8,20.3,0.8,20.3-10.2c-5.9-0.8-26.2-1.5-26.2-15.3v-51.5
			c0-18.6,9.3-27.9,27.9-27.9c18.6,0,27.9,9.3,27.9,27.9c1.7,14.2-8.5,27-22.7,28.6c-1.5,0.2-3,0.2-4.5,0.1c-7.6,0.4-5.7,5.2,0.8,6.7
			c2.5,0.6,5.1,0.8,7.6,0.8c3.4,6.7,8.4,14.7,17.8,12.7c-3.4-4.3-8.2-9.7,1.7-28.8c3.7-6.1,5.7-13.2,5.9-20.3c0.2-19-15-34.5-34-34.8
			h-0.8C397.1,69,381.6,83.4,381,102C380.9,103,380.9,103.7,381,104.4z"/>
		<path className="st0" d="M77.1,429.9V104.4c0.5-18.7-14.3-34.3-32.9-34.8h-1.8c-18.9-0.2-34.5,15-34.8,34v0.8c0.2,7.1,2.3,14.2,5.9,20.3
			c10,19,5.1,24.6,1.7,28.8c9.3,1.9,14.4-5.9,17.8-12.7c2.6,0,5.2-0.3,7.6-0.8c6.5-1.5,8.5-6.3,0.9-6.7c-14.3,0.8-26.4-10.1-27.2-24.4
			c-0.1-1.5,0-3,0.1-4.5c0-18.6,9.3-27.9,27.9-27.9s27.9,9.2,27.9,27.8v51.5c0,13.8-20.3,14.4-26.2,15.3c0,11,11,11,20.3,10.2l5.9,9.3
			v43.6c-2.6,15.5-11.2,23.3-23.3,32.2l-1.3,0.6l1.3,0.6c12.1,8.9,21,16.5,23.3,32.2v43.6l-5.9,9.3C55.1,352,44.1,352,44.1,363
			c5.9,0.8,26.2,1.5,26.2,15.3v51.5c0,18.6-9.3,27.9-27.9,27.9s-27.9-9.3-27.9-27.9c-1.7-14.2,8.5-27,22.7-28.6c1.5-0.2,3-0.2,4.5-0.1
			c7.6-0.4,5.7-5.2-0.9-6.7c-2.5-0.6-5.1-0.8-7.6-0.8c-3.4-6.7-8.5-14.7-17.8-12.7c3.4,4.3,8.2,9.7-1.7,28.8
			c-3.7,6.1-5.7,13.2-5.8,20.3c-0.2,19,15,34.5,34,34.8h0.8c18.6,0.5,34.2-14.2,34.8-32.9v-1.9H77.1z"/>
		<path className="st0" d="M381,104.4v325.5c-0.5,18.7,14.3,34.3,32.9,34.8h1.8c19,0.2,34.5-15,34.8-34v-0.8c-0.2-7.1-2.3-14.2-5.9-20.3
			c-9.9-19-5.1-24.6-1.7-28.8c-9.3-1.9-14.4,5.9-17.8,12.7c-2.6,0-5.2,0.3-7.6,0.8c-6.5,1.5-8.4,6.3-0.8,6.7
			c14.3-0.8,26.4,10.1,27.2,24.4c0.1,1.5,0,3-0.1,4.5c0,18.6-9.3,27.9-27.9,27.9c-18.6,0-27.9-9.3-27.9-27.9v-51.4
			c0-13.8,20.3-14.4,26.2-15.3c0-11-11-11-20.3-10.2l-5.9-9.3v-43.6c2.6-15.5,11.2-23.3,23.3-32.2l1.3-0.6l-1.3-0.6
			c-12.1-8.9-21-16.5-23.3-32.2v-43.6l5.9-9.3c9.3,0.8,20.3,0.8,20.3-10.2c-5.9-0.8-26.2-1.5-26.2-15.3v-51.5
			c0-18.6,9.3-27.9,27.9-27.9c18.6,0,27.9,9.3,27.9,27.9c1.7,14.2-8.5,27-22.7,28.6c-1.5,0.2-3,0.2-4.5,0.1c-7.6,0.4-5.7,5.2,0.8,6.7
			c2.5,0.6,5.1,0.8,7.6,0.8c3.4,6.7,8.4,14.7,17.8,12.7c-3.4-4.3-8.2-9.7,1.7-28.8c3.7-6.1,5.7-13.2,5.9-20.3c0.2-19-15-34.5-34-34.8
			h-0.8C397.1,69,381.6,83.4,381,102C380.9,103,380.9,103.7,381,104.4z"/>
		<path className="st0" d="M140.8,115H66.3c-18.7,0.5-34.3-14.3-34.8-32.9v-1.8v-2.8c0.2-2.3,0.6-4.6,1.3-6.7c4.2-15,17.9-25.4,33.5-25.2
			c7.1,0.2,14.2,2.3,20.3,5.9c19,9.9,24.6,5.1,28.8,1.7c1.9,9.3-5.9,14.4-12.7,17.8c0,2.6-0.3,5.2-0.9,7.6c-1.5,6.5-6.3,8.4-6.7,0.8
			C95.9,65.3,85,53.1,70.7,52.3c-1.5-0.1-3,0-4.5,0.1c-14.7,0-23.5,5.7-26.6,17.3c-0.6,2.2-1,4.5-1.1,6.7c0,1.3-0.2,2.6-0.2,3.8
			c0,18.6,9.3,27.9,27.9,27.9h51.5c13.8,0,14.4-20.3,15.3-26.2c1.5,0,2.9,0.2,4.3,0.6C133.5,93.4,134.9,105.3,140.8,115z"/>
		<path className="st0" d="M426.4,80.6c0.5,18.7-14.3,34.3-32.9,34.8h-1.8h-74.5c5.9-9.7,7.2-21.5,3.6-32.2c1.4-0.5,2.8-0.7,4.3-0.6
			c0.8,5.9,1.5,26.2,15.3,26.2h51.7c18.6,0,27.9-9.3,27.9-27.9c0-1.3,0-2.6-0.2-3.8c-0.2-2.3-0.6-4.6-1.1-6.7
			C415.5,59,406.6,53,392.1,53c-14.2-1.7-27,8.5-28.6,22.7c-0.2,1.5-0.2,3-0.1,4.5c-0.4,7.6-5.2,5.7-6.7-0.8c-0.6-2.5-0.8-5.1-0.8-7.6
			c-6.7-3.4-14.7-8.4-12.7-17.8c4.3,3.4,9.7,8.2,28.8-1.7c6.1-3.7,13.2-5.7,20.3-5.9c15.6-0.1,29.3,10.2,33.5,25.2
			c0.7,2.2,1.1,4.5,1.3,6.7C426.2,78.7,426.4,79.5,426.4,80.6z"/>
		<path className="st0" d="M391.8,419.1H66.3C47.6,418.6,32,433.4,31.5,452v1.8c-0.2,19,15,34.5,34,34.8h0.8c7.1-0.2,14.2-2.3,20.3-5.9
			c19-10,24.6-5.1,28.8-1.7c1.9-9.3-5.9-14.4-12.7-17.8c0-2.6-0.3-5.2-0.9-7.6c-1.5-6.5-6.3-8.5-6.7-0.8c0.8,14.3-10.1,26.4-24.4,27.2
			c-1.5,0.1-3,0-4.5-0.1c-18.6,0-27.9-9.3-27.9-27.9c0-18.6,9.3-27.9,27.9-27.9h51.4c13.8,0,14.4,20.3,15.3,26.2c11,0,11-11,10.2-20.3
			l9.3-5.9H196c15.5,2.6,23.3,11.2,32.2,23.3l0.6,1.3l0.6-1.3c8.9-12.1,16.5-21,32.2-23.3h43.6l9.3,5.9c-0.9,9.3-0.9,20.3,10.2,20.3
			c0.8-5.9,1.5-26.2,15.3-26.2h51.5c18.6,0,27.9,9.3,27.9,27.9c0,18.6-9.3,27.9-27.9,28c-14.2,1.7-27-8.5-28.6-22.7
			c-0.2-1.5-0.2-3-0.1-4.5c-0.4-7.6-5.2-5.7-6.7,0.8c-0.6,2.5-0.8,5.1-0.8,7.6c-6.7,3.4-14.7,8.5-12.7,17.8c4.3-3.4,9.7-8.2,28.8,1.7
			c6.1,3.7,13.2,5.7,20.3,5.9c19,0.2,34.5-15,34.8-34v-0.8c0.7-18.5-13.8-34.1-32.3-34.8C393.2,419.1,392.4,419.1,391.8,419.1z"/>
		<path className="st0" d="M140.8,115H66.3c-18.7,0.5-34.3-14.3-34.8-32.9v-1.8v-2.8c0.2-2.3,0.6-4.6,1.3-6.7c4.2-15,17.9-25.4,33.5-25.2
			c7.1,0.2,14.2,2.3,20.3,5.9c19,9.9,24.6,5.1,28.8,1.7c1.9,9.3-5.9,14.4-12.7,17.8c0,2.6-0.3,5.2-0.9,7.6c-1.5,6.5-6.3,8.4-6.7,0.8
			C95.9,65.3,85,53.1,70.7,52.3c-1.5-0.1-3,0-4.5,0.1c-14.7,0-23.5,5.7-26.6,17.3c-0.6,2.2-1,4.5-1.1,6.7c0,1.3-0.2,2.6-0.2,3.8
			c0,18.6,9.3,27.9,27.9,27.9h51.5c13.8,0,14.4-20.3,15.3-26.2c1.5,0,2.9,0.2,4.3,0.6C133.5,93.4,134.9,105.3,140.8,115z"/>
		<path className="st0" d="M426.4,80.6c0.5,18.7-14.3,34.3-32.9,34.8h-1.8h-74.5c5.9-9.7,7.2-21.5,3.6-32.2c1.4-0.5,2.8-0.7,4.3-0.6
			c0.8,5.9,1.5,26.2,15.3,26.2h51.7c18.6,0,27.9-9.3,27.9-27.9c0-1.3,0-2.6-0.2-3.8c-0.2-2.3-0.6-4.6-1.1-6.7
			C415.5,59,406.6,53,392.1,53c-14.2-1.7-27,8.5-28.6,22.7c-0.2,1.5-0.2,3-0.1,4.5c-0.4,7.6-5.2,5.7-6.7-0.8c-0.6-2.5-0.8-5.1-0.8-7.6
			c-6.7-3.4-14.7-8.4-12.7-17.8c4.3,3.4,9.7,8.2,28.8-1.7c6.1-3.7,13.2-5.7,20.3-5.9c15.6-0.1,29.3,10.2,33.5,25.2
			c0.7,2.2,1.1,4.5,1.3,6.7C426.2,78.7,426.4,79.5,426.4,80.6z"/>
		<path className="st0" d="M313.4,78c7.8-13.6,5.4-30.6-5.7-41.5c-5.2-5-11.7-8.5-18.6-10.2c-20.5-6.3-21-13.8-21.6-19
			c-8,5.1-5.9,14.4-3.6,21.6c-1.8,1.8-3.5,3.8-4.9,5.9c-3.6,5.7-1.5,10.4,4.3,5.2c13.2-12.1,26.8-11.4,39.4,1.3
			c10.4,10.4,12.5,20.8,6.5,31.1l-1.9-1.9c-12.9-13.6-34.3-14.2-47.8-1.3l-1.3,1.3l-2.8,2.8l-1.5-1.5l-20.5-20.5l19.7-19.7
			c2.3-2.4,2.3-6.1-0.1-8.4c-1.4-1.4-3.5-2-5.4-1.6l-3.2,0.6c-4.5-5.5-9.9-11.2-15.8-10.4c-5.7-0.8-11.2,4.9-15.8,10.4l-3.2-0.6
			c-3.2-0.7-6.3,1.3-7,4.5l-0.1,1.3c0,1.6,0.6,3.1,1.7,4.3l19.7,19.7l-20.8,20.5l-1.5,1.5l-2.8-2.8C185.9,57,164.5,56.4,151,69.3
			l-1.3,1.3l-1.9,1.9c-5.9-10.4-3.9-20.8,6.5-31.1c8.8-11.2,25.1-13.1,36.3-4.2c1.1,0.9,2.2,1.9,3.1,3c5.7,5.1,7.8,0.2,4.3-5.2
			c-1.3-2.2-3-4.3-4.9-5.9c2.3-7.2,4.5-16.3-3.6-21.6c-0.6,5.2-1.1,12.7-21.6,19c-7,1.7-13.4,5.2-18.6,10.2
			c-11.2,10.9-13.5,28-5.7,41.5c-7.8,13.6-5.4,30.6,5.7,41.5c5.2,5,11.7,8.5,18.6,10.2c20.5,6.3,21,13.8,21.6,19
			c8-5.1,5.9-14.4,3.6-21.6c1.8-1.8,3.5-3.8,4.9-5.9c3.6-5.7,1.5-10.4-4.3-5.2c-13.2,12.1-26.8,11.4-39.4-1.3
			c-10.4-10.4-12.5-20.8-6.5-31.1l1.9,1.9c12.9,13.6,34.3,14.2,47.8,1.3l1.3-1.3l2.8-2.8l1.5,1.5l20.5,20.5l-19.7,19.7
			c-1.1,1.1-1.7,2.7-1.7,4.3c0,3.3,2.7,5.8,5.9,5.8l1.3-0.2l3.2-0.6c4.5,5.5,9.9,11.2,15.8,10.4c5.7,0.8,11.2-4.9,15.8-10.4l3.2,0.6
			c3.2,0.7,6.3-1.3,7-4.5l0.1-1.3c0-1.6-0.6-3.1-1.7-4.3l-19.4-19.7l20.5-20.5l1.5-1.5l2.8,2.8C271,99,292.4,99.6,306,86.7l1.3-1.3
			l1.9-1.9c5.9,10.4,3.9,20.8-6.5,31.1c-8.8,11.2-25.1,13.1-36.3,4.2c-1.1-0.9-2.2-1.9-3.1-3c-5.7-5.1-7.8-0.2-4.3,5.2
			c1.3,2.2,3,4.3,4.9,5.9c-2.3,7.2-4.5,16.3,3.6,21.6c0.6-5.2,1.1-12.7,21.6-19c7-1.7,13.4-5.2,18.6-10.2
			C318.9,108.6,321.1,91.6,313.4,78z M226.9,44.3c-6.5-6.5-2.3-15.3,2.1-21.8c4.5,6.5,8.7,15.3,2.1,21.8l-2.1,2.1L226.9,44.3z
			M194.5,80.8c-13.1,13.1-26.2,13.1-39.4,0c-0.9-0.9-1.8-1.8-2.6-2.8c0.9-0.8,1.7-1.9,2.6-2.8c13.1-13.1,26.2-13.1,39.4,0l2.8,2.8
			L194.5,80.8z M231,111.7c6.5,6.5,2.3,15.3-2.1,21.8c-4.5-6.5-8.7-15.3-2.1-21.8l2.1-2.1L231,111.7z M249.6,79.5L229.1,100
			l-20.5-20.5l-1.5-1.5l1.5-1.5L229.1,56l20.5,20.5l1.5,1.5L249.6,79.5z M302.8,80.8c-13.1,13.1-26.2,13.1-39.4,0l-2.8-2.8l2.8-2.8
			c13.1-13.1,26.2-13.1,39.4,0c0.9,0.9,1.8,1.8,2.6,2.8C304.6,79.1,303.8,80,302.8,80.8L302.8,80.8z"/>
		<path className="st0" d="M246.3,352.2c0-30.2,0-60.2,0-90.5c-9.8,0-19.3,0-29.3,0c0,30.1,0,60.2,0,90.5c-9,0-17.6,0-26.7,0
			c0-1.5,0-2.8,0-4.2c0.6-51.1,1.1-102.3,1.8-153.4c0-1.5,0.4-3,0.9-4.4c6.9-19.1,26.6-30.2,46.9-26.5c18.9,3.4,33.1,20.7,33.1,40.6
			c0,48,0,96-0.1,144c0,1.2,0,2.5,0,4C264,352.2,255.4,352.2,246.3,352.2z M246.4,253.6c0-20.5,0.2-40.5-0.2-60.6
			c-0.1-5.1-1.9-10.1-3.7-15c-1.6-4.5-5-7.6-10.2-7.7c-5.4-0.1-9,2.9-10.7,7.5c-1.9,5.1-3.7,10.4-3.8,15.6
			c-0.6,19.5-0.5,39.1-0.7,58.6c0,0.5,0.3,0.9,0.4,1.4C227.2,253.6,236.7,253.6,246.4,253.6z"/>
		<path className="st0" d="M275,253.6v6.9h14.7v77.8c0,28.8-9.7,41.1-21.8,41.1h-79.1c-12.1,0-21.8-12.3-21.8-41.1v-14.7h-26.2v14.7
			c0,26.5,21.6,48,48,48h79.1c26.5-0.1,48-21.6,48-48v-84.7H275L275,253.6z"/>
	</svg>
);

const LogoFooter = () => (
	<svg version="1.1" x="0px" y="0px" fill="#fff" viewBox="0 0 2000 2000" style={{ enableBackground:"new 0 0 2000 2000" }}>
		<path className="st0" d="M1322.1,696.1v689.5c-1.1,39.6,30.2,72.5,69.8,73.6h3.8c40.2,0.4,73.1-31.8,73.6-72v-1.6
		c-0.4-15.2-4.8-30-12.6-43.1c-21.1-40.4-10.8-52-3.6-61c-19.7-4-30.5,12.6-37.7,26.9c-5.4,0-10.8,0.6-16.2,1.8
		c-13.9,3.1-17.9,13.5-1.8,14.3c30.2-1.7,56,21.4,57.7,51.6c0.2,3.1,0.1,6.3-0.3,9.4c0,39.5-19.7,59.2-59.2,59.2
		s-59.2-19.7-59.2-59.2v-109c0-29.2,43.1-30.5,55.6-32.3c0-23.3-23.3-23.3-43.1-21.5l-12.6-19.7v-92.4c5.4-32.8,23.8-49.3,49.3-68.2
		l2.7-1.3l-2.7-1.3c-25.6-18.8-44.4-35-49.3-68.2v-92.4l12.6-19.7c19.7,1.8,43.1,1.8,43.1-21.5c-12.6-1.8-55.6-3.1-55.6-32.3v-109
		c0-39.5,19.7-59.2,59.2-59.2s59.2,19.7,59.2,59.2c3.5,30-18,57.2-48,60.7c-3.1,0.4-6.3,0.5-9.4,0.3c-16.2,0.9-12.1,11.2,1.8,14.3
		c5.3,1.2,10.7,1.8,16.2,1.8c7.2,14.3,17.9,31,37.7,26.9c-7.2-9-17.5-20.6,3.6-61c7.8-13,12.1-27.9,12.6-43.1
		c0.4-40.2-31.8-73.1-72-73.6h-1.6c-39.2-1.4-72.2,29.3-73.6,68.5C1322,693,1322,694.5,1322.1,696.1z"/>
		<path className="st0" d="M678.3,1385.6V696c1.1-39.6-30.2-72.5-69.8-73.6h-3.8c-40.2-0.5-73.1,31.8-73.6,72v1.6
		c0.4,15.2,4.8,30,12.6,43.1c21.1,40.4,10.8,52,3.6,61c19.7,4,30.5-12.6,37.7-26.9c5.4,0,10.8-0.6,16.2-1.8
		c13.9-3.1,17.9-13.5,1.8-14.3c-30.2,1.7-56-21.4-57.7-51.6c-0.2-3.1-0.1-6.3,0.3-9.4c0-39.5,19.7-59.2,59.2-59.2
		c39.5,0,59.2,19.7,59.2,59.2v109c0,29.2-43.1,30.5-55.6,32.3c0,23.3,23.3,23.3,43.1,21.5l12.6,19.7V971
		c-5.4,32.8-23.8,49.3-49.3,68.2l-2.7,1.3l2.7,1.3c25.6,18.8,44.4,35,49.3,68.2v92.4l-12.6,19.7c-19.7-1.8-43.1-1.8-43.1,21.5
		c12.6,1.8,55.6,3.2,55.6,32.3v109.1c0,39.5-19.7,59.2-59.2,59.2s-59.2-19.7-59.3-59.2c-3.5-30,18-57.2,48-60.7
		c3.1-0.4,6.3-0.5,9.4-0.3c16.2-0.9,12.1-11.2-1.8-14.3c-5.3-1.2-10.7-1.8-16.2-1.8c-7.2-14.3-17.9-31-37.7-26.9
		c7.2,9,17.5,20.6-3.6,61c-7.8,13-12.1,27.9-12.5,43.1c-0.5,40.2,31.8,73.1,72,73.6h1.6c39.5,1.1,72.5-30,73.6-69.6L678.3,1385.6z"/>
		<path className="st0" d="M1322.1,696.1v689.5c-1.1,39.6,30.2,72.5,69.8,73.6h3.8c40.2,0.4,73.1-31.8,73.6-72v-1.6
		c-0.4-15.2-4.8-30-12.6-43.1c-21.1-40.4-10.8-52-3.6-61c-19.7-4-30.5,12.6-37.7,26.9c-5.4,0-10.8,0.6-16.2,1.8
		c-13.9,3.1-17.9,13.5-1.8,14.3c30.2-1.7,56,21.4,57.7,51.6c0.2,3.1,0.1,6.3-0.3,9.4c0,39.5-19.7,59.2-59.2,59.2
		s-59.2-19.7-59.2-59.2v-109c0-29.2,43.1-30.5,55.6-32.3c0-23.3-23.3-23.3-43.1-21.5l-12.6-19.7v-92.4c5.4-32.8,23.8-49.3,49.3-68.2
		l2.7-1.3l-2.7-1.3c-25.6-18.8-44.4-35-49.3-68.2v-92.4l12.6-19.7c19.7,1.8,43.1,1.8,43.1-21.5c-12.6-1.8-55.6-3.1-55.6-32.3v-109
		c0-39.5,19.7-59.2,59.2-59.2s59.2,19.7,59.2,59.2c3.5,30-18,57.2-48,60.7c-3.1,0.4-6.3,0.5-9.4,0.3c-16.2,0.9-12.1,11.2,1.8,14.3
		c5.3,1.2,10.7,1.8,16.2,1.8c7.2,14.3,17.9,31,37.7,26.9c-7.2-9-17.5-20.6,3.6-61c7.8-13,12.1-27.9,12.6-43.1
		c0.4-40.2-31.8-73.1-72-73.6h-1.6c-39.2-1.4-72.2,29.3-73.6,68.5C1322,693,1322,694.5,1322.1,696.1z"/>
		<path className="st0" d="M813.3,718.5H655.4c-39.6,1.1-72.5-30.2-73.6-69.8v-3.8v-5.8c0.4-4.9,1.3-9.7,2.7-14.3
		c8.8-31.8,37.9-53.7,70.9-53.4c15.2,0.4,30,4.8,43.1,12.6c40.4,21.1,52,10.8,61,3.6c4,19.7-12.6,30.5-26.9,37.7
		c0,5.4-0.6,10.8-1.8,16.2c-3.1,13.9-13.5,17.9-14.3,1.8c1.7-30.2-21.4-56-51.6-57.7c-3.1-0.2-6.3-0.1-9.4,0.3
		c-31,0-49.8,12.1-56.5,36.8c-1.2,4.7-2,9.5-2.2,14.3c0,2.7-0.4,5.4-0.4,8.1c0,39.5,19.7,59.2,59.2,59.2h109
		c29.2,0,30.5-43.1,32.3-55.6c3-0.1,6.1,0.4,9,1.3C798,672.8,800.8,697.8,813.3,718.5z"/>
		<path className="st0" d="M1418.5,645.4c1.1,39.6-30.2,72.5-69.8,73.6h-3.8H1187c12.5-20.5,15.3-45.5,7.6-68.2c2.9-1,5.9-1.4,9-1.3
		c1.8,12.6,3.1,55.6,32.3,55.6h109.5c39.5,0,59.2-19.7,59.2-59.2c0.1-2.7-0.1-5.4-0.4-8.1c-0.5-4.8-1.2-9.6-2.2-14.3
		c-6.7-24.2-25.6-36.8-56.5-36.8c-30-3.5-57.2,18-60.7,48c-0.4,3.1-0.5,6.3-0.3,9.4c-0.9,16.2-11.2,12.1-14.3-1.8
		c-1.2-5.3-1.8-10.7-1.8-16.2c-14.3-7.2-30.9-17.9-26.9-37.7c9,7.2,20.6,17.5,61-3.6c13-7.8,27.9-12.1,43.1-12.6
		c33-0.3,62,21.6,70.9,53.4c1.4,4.7,2.3,9.5,2.7,14.3C1418.1,641.3,1418.5,643.1,1418.5,645.4z"/>
		<path className="st0" d="M1345,1362.7H655.4c-39.6-1.1-72.5,30.2-73.6,69.8v3.8c-0.5,40.2,31.8,73.1,72,73.6h1.6
		c15.2-0.4,30-4.8,43.1-12.6c40.4-21.1,52-10.8,61-3.6c4-19.7-12.6-30.5-26.9-37.7c0-5.4-0.6-10.8-1.8-16.2
		c-3.1-13.9-13.5-17.9-14.3-1.8c1.7,30.2-21.4,56-51.6,57.7c-3.1,0.2-6.3,0.1-9.4-0.3c-39.5,0-59.2-19.7-59.2-59.2
		s19.7-59.2,59.2-59.2h109c29.2,0,30.5,43.1,32.3,55.6c23.3,0,23.3-23.3,21.5-43.1L838,1377h92.4c32.8,5.4,49.3,23.8,68.2,49.3
		l1.3,2.7l1.3-2.7c18.8-25.6,35-44.4,68.2-49.3h92.4l19.7,12.6c-1.8,19.7-1.8,43.1,21.5,43.1c1.8-12.6,3.1-55.6,32.3-55.6h109.1
		c39.5,0,59.2,19.7,59.2,59.2s-19.7,59.3-59.2,59.3c-30,3.5-57.2-18-60.7-48c-0.4-3.1-0.5-6.3-0.3-9.4c-0.9-16.2-11.2-12.1-14.3,1.8
		c-1.2,5.3-1.8,10.7-1.8,16.2c-14.3,7.2-30.9,17.9-26.9,37.7c9-7.2,20.6-17.5,61,3.6c13,7.8,27.9,12.1,43.1,12.6
		c40.2,0.4,73.1-31.8,73.6-72v-1.6c1.5-39.2-29.1-72.2-68.4-73.7C1348.1,1362.7,1346.5,1362.7,1345,1362.7z"/>
		<path className="st0" d="M813.3,718.5H655.4c-39.6,1.1-72.5-30.2-73.6-69.8v-3.8v-5.8c0.4-4.9,1.3-9.7,2.7-14.3
		c8.8-31.8,37.9-53.7,70.9-53.4c15.2,0.4,30,4.8,43.1,12.6c40.4,21.1,52,10.8,61,3.6c4,19.7-12.6,30.5-26.9,37.7
		c0,5.4-0.6,10.8-1.8,16.2c-3.1,13.9-13.5,17.9-14.3,1.8c1.7-30.2-21.4-56-51.6-57.7c-3.1-0.2-6.3-0.1-9.4,0.3
		c-31,0-49.8,12.1-56.5,36.8c-1.2,4.7-2,9.5-2.2,14.3c0,2.7-0.4,5.4-0.4,8.1c0,39.5,19.7,59.2,59.2,59.2h109
		c29.2,0,30.5-43.1,32.3-55.6c3-0.1,6.1,0.4,9,1.3C798,672.8,800.8,697.8,813.3,718.5z"/>
		<path className="st0" d="M1418.5,645.4c1.1,39.6-30.2,72.5-69.8,73.6h-3.8H1187c12.5-20.5,15.3-45.5,7.6-68.2c2.9-1,5.9-1.4,9-1.3
		c1.8,12.6,3.1,55.6,32.3,55.6h109.5c39.5,0,59.2-19.7,59.2-59.2c0.1-2.7-0.1-5.4-0.4-8.1c-0.5-4.8-1.2-9.6-2.2-14.3
		c-6.7-24.2-25.6-36.8-56.5-36.8c-30-3.5-57.2,18-60.7,48c-0.4,3.1-0.5,6.3-0.3,9.4c-0.9,16.2-11.2,12.1-14.3-1.8
		c-1.2-5.3-1.8-10.7-1.8-16.2c-14.3-7.2-30.9-17.9-26.9-37.7c9,7.2,20.6,17.5,61-3.6c13-7.8,27.9-12.1,43.1-12.6
		c33-0.3,62,21.6,70.9,53.4c1.4,4.7,2.3,9.5,2.7,14.3C1418.1,641.3,1418.5,643.1,1418.5,645.4z"/>
		<path className="st0" d="M1178.9,640c16.5-28.6,11.5-64.8-12.1-87.9c-11-10.5-24.7-18-39.5-21.5c-43.5-13.5-44.4-29.2-45.8-40.4
		c-17,10.8-12.6,30.5-7.6,45.8c-3.8,3.8-7.3,8-10.3,12.6c-7.6,12.1-3.1,22,9,11.2c27.9-25.6,57-24.2,83.4,2.7c22,22,26.5,44,13.9,66
		l-4-4c-27.2-28.7-72.6-30-101.4-2.7l-2.7,2.7l-5.8,5.8l-3.1-3.1l-43.5-43.5l41.7-41.7c4.9-5,4.8-12.9-0.2-17.8
		c-3-3-7.3-4.2-11.5-3.3l-6.7,1.3c-9.4-11.7-21.1-23.8-33.7-22c-12.1-1.8-23.8,10.3-33.7,22l-6.7-1.3c-6.7-1.5-13.4,2.7-14.9,9.4
		l-0.3,2.7c0,3.3,1.3,6.5,3.6,9l41.7,41.7L945.3,627l-3.1,3.1l-5.8-5.8c-27.2-28.7-72.6-30-101.4-2.7l-2.7,2.7l-4,4
		c-12.6-22-8.1-44,13.9-66c18.8-23.7,53.2-27.7,76.8-8.9c2.4,1.9,4.6,4,6.6,6.2c12.1,10.8,16.6,0.4,9-11.2c-2.8-4.7-6.2-9-10.3-12.6
		c4.9-15.2,9.4-34.5-7.6-45.8c-1.3,11.2-2.2,26.9-45.8,40.4c-14.8,3.6-28.4,11-39.5,21.5c-23.7,23.1-28.7,59.3-12.1,87.9
		c-16.5,28.6-11.5,64.8,12.1,87.9c11,10.5,24.7,18,39.5,21.5c43.5,13.5,44.4,29.2,45.8,40.4c17-10.8,12.6-30.5,7.6-45.8
		c3.8-3.8,7.3-8,10.3-12.6c7.6-12.1,3.1-22-9-11.2c-27.9,25.6-57,24.2-83.5-2.7c-22-22-26.5-44-13.9-66l4,4
		c27.2,28.7,72.6,30,101.4,2.7l2.7-2.7l5.8-5.8l3.1,3.1l43.5,43.5L947,738.2c-2.3,2.4-3.6,5.6-3.6,9c0,6.9,5.6,12.5,12.5,12.4
		l2.7-0.3l6.7-1.3c9.4,11.7,21.1,23.8,33.7,22c12.1,1.8,23.8-10.3,33.6-22l6.7,1.3c6.7,1.5,13.4-2.7,14.9-9.4l0.3-2.7
		c0-3.3-1.3-6.5-3.6-9l-41.7-41.7l43.5-43.5l3.1-3.1l5.8,5.8c27.2,28.7,72.6,30,101.4,2.7l2.7-2.7l4-4c12.6,22,8.1,44-13.9,66
		c-18.8,23.7-53.2,27.7-76.8,8.9c-2.4-1.9-4.6-4-6.6-6.2c-12.1-10.8-16.6-0.4-9,11.2c2.8,4.7,6.2,9,10.3,12.6
		c-4.9,15.2-9.4,34.5,7.6,45.8c1.3-11.2,2.2-26.9,45.8-40.4c14.8-3.6,28.4-11,39.5-21.5C1190.5,704.8,1195.5,668.6,1178.9,640z
			M995.9,568.7c-13.9-13.9-4.9-32.3,4.5-46.2c9.4,13.9,18.4,32.3,4.5,46.2l-4.5,4.5L995.9,568.7z M927.3,645.8
		c-27.8,27.8-55.6,27.8-83.5,0c-1.9-1.8-3.7-3.8-5.4-5.8c1.8-1.8,3.6-4,5.4-5.8c27.8-27.8,55.6-27.8,83.5,0l5.8,5.8L927.3,645.8z
			M1004.4,711.3c13.9,13.9,4.9,32.3-4.5,46.2c-9.4-13.9-18.4-32.3-4.5-46.2l4.5-4.5L1004.4,711.3z M1043.9,643.1l-43.5,43.5
		l-43.5-43.5l-3.1-3.1l3.1-3.1l43.5-43.5l43.5,43.5l3.1,3.1L1043.9,643.1z M1156.5,645.8c-27.8,27.8-55.6,27.8-83.4,0l-5.8-5.8
		l5.8-5.8c27.8-27.8,55.6-27.8,83.4,0c1.9,1.8,3.7,3.8,5.4,5.8C1160.5,642.2,1158.6,644.2,1156.5,645.8L1156.5,645.8z"/>
		<path className="st0" d="M1036.8,1220.8c0-64.1,0-127.7,0-191.8c-20.8,0-41,0-62,0c0,63.8,0,127.5,0,191.8c-19.1,0-37.4,0-56.8,0
		c0-3.1,0-5.9,0-8.7c1.2-108.4,2.4-216.7,3.7-325.1c0-3.1,0.8-6.4,1.9-9.3c14.8-40.5,56.5-64,99.5-56.3c40.2,7.2,70.2,43.8,70.2,86
		c0,101.7-0.1,203.4-0.2,305c0,2.6,0,5.2,0,8.3C1074.2,1220.8,1056.1,1220.8,1036.8,1220.8z M1036.9,1012c0-43.4,0.5-85.9-0.4-128.3
		c-0.2-10.6-4-21.5-7.8-31.6c-3.5-9.4-10.5-16-21.6-16.3c-11.5-0.2-19.1,6.1-22.7,15.9c-3.9,10.7-7.7,22-8,33.2
		c-1.3,41.4-1.1,82.8-1.4,124.2c0,1,0.5,2,0.8,2.9C996.4,1012,1016.7,1012,1036.9,1012z"/>
		<path className="st0" d="M1097.5,1012.1v14.8h30.9v164.7c0,61-20.6,87-46.2,87H914.5c-25.6,0-46.2-26-46.2-87v-30.9h-55.6v30.9
		c0.1,56.2,45.6,101.7,101.8,101.8h167.8c56.2-0.1,101.7-45.7,101.8-101.8v-179.4L1097.5,1012.1L1097.5,1012.1z"/>
	</svg>
);

export {
    Logo, LogoFooter
}