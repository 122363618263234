import React, { useEffect, useState } from 'react';
// import logo12 from "../../assets/images/logo.svg";
import { Link, NavLink } from "react-router-dom";
import { Logo } from '../Logo';
import { scroll } from '../../assets/functions';

export const Header = () => {
    const [toggle, setToggle] = useState(false)
    const [y, setY] = useState(0);

    const scrollTo = (e) => {
      scroll(e);
      if (toggle) {
        setToggle(false)
      }
    }

    const handleNavigation = (e) => {
        if (100 < window.scrollY) {
            setY(true);
          } else {
            setY(0);
          }
      };
    
      useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", (e) => handleNavigation(e));
      }, []);
  return (
    <header className={`site-header js-site-header ${y ? "scrolled" : ""}`}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-6 col-lg-2 site-logo" data-aos="fade"><Link to="/"><Logo /></Link></div>
          <div className="col-6 col-lg-8">
            <div className="d-none d-lg-flex align-items-center justify-content-center">

              <ul className="list-unstyled menu-desktop site-logo d-flex mb-0">
                <li className="active"><NavLink onClick={() => scrollTo("top")} to="#top">Home</NavLink></li>
                <li><NavLink onClick={() => scrollTo("rooms")} to="#rooms">Rooms</NavLink></li>
                <li><NavLink onClick={() => scrollTo("about")}  to="#about">Our Hotel</NavLink></li>
                <li><NavLink onClick={() => scrollTo("gallery")}  to="#events">Gallery</NavLink></li>
                <li><NavLink onClick={() => scrollTo("contact")}  to="#contact">Contact</NavLink></li>
              </ul>

            </div>
         
         
            <div className={`${toggle && "site-menu-toggle js-site-menu-toggle aos-init aos-animate open"} site-menu-toggle js-site-menu-toggle d-lg-none`} onClick={() => setToggle(!toggle)}  data-aos="fade">
              <span></span>
              <span></span>
              <span></span>
            </div>

            <div className="site-navbar js-site-navbar" style={{ display: toggle ? "block" : "none" }}>
              <nav role="navigation">
                <div className="container">
                  <div className="row full-height align-items-center">
                    <div className="col-md-6 mx-auto">
                      <ul className="list-unstyled menu">
                        <li className="active"><NavLink className="text-decoration-none" onClick={() => scrollTo("top")} to="#top">Home</NavLink></li>
                        <li><NavLink className="text-decoration-none" onClick={() => scrollTo("rooms")} to="#rooms">Rooms</NavLink></li>
                        <li><NavLink className="text-decoration-none" onClick={() => scrollTo("about")}  to="#about">Our Hotel</NavLink></li>
                        <li><NavLink className="text-decoration-none" onClick={() => scrollTo("gallery")}  to="#events">Gallery</NavLink></li>
                        <li><NavLink className="text-decoration-none" onClick={() => scrollTo("contact")}  to="#contact">Contact</NavLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="col-6 col-lg-2 d-none d-lg-flex">
            <div className="d-flex align-items-center justify-content-end w-100">
              <button onClick={() => scroll("contact")} className="btn-menu-selce">Book Now</button>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
