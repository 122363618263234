import React from 'react';
import img from '../../assets/images/main.svg';

const HeaderOverlay = () => {
    return (
        <section className="site-hero overlay" style={{ backgroundImage: `url(${img})`}} id='top'>
            <div className="container">
            <div className="row site-hero-inner justify-content-center align-items-center position-relative">
                <div className="col-md-10 text-end" data-aos="fade-up">
                {/* <span className="custom-caption text-uppercase text-white d-block  mb-3">Welcome To 5 <span className="fa fa-star text-primary"></span>   Hotel</span> */}
                <h1 className="heading">Welcome to <br />Pansion Selce</h1>
                </div>
            </div>
            </div>

            <a className="mouse smoothscroll" href="#next">
            <div className="mouse-icon">
                <span className="mouse-wheel"></span>
            </div>
            </a>
        </section>
    )
}

export default HeaderOverlay;
