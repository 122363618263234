import './App.css';
import { Header } from './Components/Header/Header';
import hero from "./assets/images/hero.svg";
import hero1 from "./assets/images/hero1.svg";
import Contact from './Components/Contact/Contact';
import Hero from './Components/Hero/Hero';
import { Fragment } from 'react';
// import FormReservation from './Components/FormReservation/FormReservation';
import ReserveNow from './Components/ReserveNow/ReserveNow';
// import RoomsSuites from './Components/RoomsSuites/RoomsSuites';
import Events from './Components/Events/Events';
import SliderPhotos from './Components/SliderPhotos/SliderPhotos';
import Footer from './Components/Footer/Footer';
import HeaderOverlay from './Components/HeaderOverlay/HeaderOverlay';
import { sliderData } from './assets/dummyData/sliderData';

function App() {
  return (
    <div className="">
    {/* <div className="menu-open"> */}
      <Header />
      <HeaderOverlay />
      {/* <FormReservation /> */}
      <Hero
        customClass="hero-bg-light"
        hero={hero}
        id="about2"
        desc={
          <Fragment>
            <h2>Pansion Selce</h2>
            <p>Selce is located in a picturesque bay on the northeastern edge of the Kvarner Gulf, 40 km south of Rijeka, on one of the most naturally diverse and picturesque parts of the Adriatic coast and the entire Mediterranean. Beautiful natural surroundings, hidden and rugged coves, a sea so clean you can see shellfish moving on its bottom, and beaches full of unforgettable sea adventures.</p>
            <h2>History</h2>
            <p>In this small place, tourists can visit the following sights:
              <br />- Church St. Katarina -&#62; built 1888., in the neo-baroque style
              <br />- Chapel St. Katarina -&#62; pre-Romanesque style
              <br />- Chapel St. Josip -&#62; baroque style
              <br />- Chapel St. Fabian and St. Sebastian -&#62; in the neo-baroque style
              <br />- Chapel St. Juraj -&#62; pre-Romanesque style</p>
          </Fragment>
        }
      />
      <Hero
        customClass="hero-bg-dark"
        hero={hero1}
        id="about1"
        desc={
          <Fragment>
            <p><b>Pansion Selce </b>is a holiday experience that comes to life with great ease. The Pansion Selce hides many small pleasures. </p>
            <p><b>Pansion Selce,</b> our exclusive family-run, newly renovated opened it’s doors in 2023. </p>
            <p>It’s location is right next to the centre. You can take a romantic walk along the promenade, where you can find a lot of content. A carefree retreat with a priceless view of the Adriatic Sea, just a few steps from a pebble beach.</p>
          </Fragment>
        }
      />
      {/* <RoomsSuites /> */}
      
      <Events />
      <SliderPhotos data={sliderData} />
      <Hero
        customClass="hero-bg-light2"
        hero={hero}
        id="about"
        desc={
          <Fragment>
            <h2>Our Hotel</h2>
            <p>Three kilometers southeast of Crikvenica, on the shore of the Vinodol canal at an altitude of 22m on a limestone slope in the cove of the same name, is the town and port of Selce. Selce is the southernmost place of the Crikvenica Riviera.Pansion Selce is elegantly furnished with wooden decor that creates a pleasant and cozy ambience. The comfortable air-conditioned rooms are provided with modern amenities, and free Wi-Fi. Guests can relax while sipping a cup of aromatic coffee in the bar or indulge in a wide selection of drinks, meat dishes, fresh and delicious fish specialties in the restaurant with its inviting terrace. (EXAMPLE)The hotel has 22 rooms and 4 suites, reception desk, cafe bar, free Wi-Fi Internet connection, mini bar, room service (7-22 h), parking place, the elegant board and restaurant that offers a variety of local and international meat and fish specialties, and an extensive wine list. Near the restaurant is patisserie. Last major renovation was in 2023. (EXAMPLE)Pansion outdoor activitiesEntertainment facilities are nowadays an indispensable part of tourism. As tourism progresses, the need for content increases so that tourists can spend their time in the most fun and pleasant way possible.</p>
          </Fragment>
        }
      />
      <ReserveNow />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
