const scroll = (id) => {
    const section = document.querySelector( `#${id}` );
    if(section) {
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
    return null;
};

const validFields = (obj) => {
    let check = false;
    Object.keys(obj).forEach((key) => {
        if (obj[key].length > 2) {
            return false;
        } else {
            if (!check) {
                check = key;
            }
        }
    })
    return check;
}

export {
    scroll, validFields
}
