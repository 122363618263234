import img1 from "../images/slider-1.jpg"
import img2 from "../images/slider-2.jpg"
import img3 from "../images/slider-3.jpg"
import img4 from "../images/slider-4.jpg"
import img5 from "../images/slider-5.jpg"
import img6 from "../images/slider-6.jpg"
import img7 from "../images/slider-7.jpg"

const sliderData = [
    {
        id: "01",
        img: img1,
        title: "Rooms",
        subTitle: "Enjoying a 60 min relaxing massage with delicious almond oil.",
    },
    {
        id: "02",
        img: img2,
        title: "Restaurant",
        subTitle: "New breakfast experience in your life with best morning scenery",
    },
    {
        id: "03",
        img: img3,
        title: "Lounge Bar",
        subTitle: "Make a unforgetable experience on your journey with adventure",
    },
    {
        id: "04",
        img: img4,
        title: "Rooms",
        subTitle: "Enjoying a 60 min relaxing massage with delicious almond oil.",
    },
    {
        id: "05",
        img: img5,
        title: "Restaurant",
        subTitle: "New breakfast experience in your life with best morning scenery",
    },
    {
        id: "06",
        img: img6,
        title: "Lounge Bar",
        subTitle: "Make a unforgetable experience on your journey with adventure",
    },
    {
        id: "07",
        img: img7,
        title: "Rooms",
        subTitle: "Enjoying a 60 min relaxing massage with delicious almond oil.",
    },
    {
        id: "08",
        img: img2,
        title: "Restaurant",
        subTitle: "New breakfast experience in your life with best morning scenery",
    },
    {
        id: "09",
        img: img3,
        title: "Lounge Bar",
        subTitle: "Make a unforgetable experience on your journey with adventure",
    },
];

export {
    sliderData,
}